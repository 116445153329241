<template>
   <div class="tw-mt-6 tw-mx-8 tw-cursor-pointer">
    <template v-if="!loading">

    <div class="tw-flex tw-flex-col" >
         
      <div
        v-for="(item , index) in sortCheapestFlight.slice(0,1)" :key="index"
        class="flight-container tw-relative"
       >
      
       <div class="tw-ml-4">
           <PlaneIcon class="tw-w-6 tw-h-6 tw-mt-4"/>  
          </div>
              <div
        class="
          recommend-label
          tw-right-0 tw-absolute tw-mr-4 tw-rounded-full tw-top-0 tw-mt-4
        "
      >
        RECOMMENDED
      </div>
      <FlightsInfo 
      :flight="item"
      :departure_at="item.departure_at"
      :arrival_at="item.arrival_at"
      :city_from="item.city_from"
      :city_code_from="item.city_code_from"
      :airport_from="item.airport_from"
      :airport_from_code="item.airport_from_code"
      :city_to="item.city_to"
      :city_code_to="item.city_code_to"
      :airport_to="item.airport_to"
      :airport_to_code="item.airport_to_code"
      :itinerary="item.itinerary"
      :duration="item.duration"
      :price="item.price[0].price"
      />
    
      </div>
    </div>
    <div class="tw-flex tw-flex-col">
      <div
        v-for="(item , index) in sortCheapestFlight.slice(1)" :key="index"
        class="flight-container tw-relative"
      >
       <div class="tw-ml-4">
           <PlaneIcon class="tw-w-6 tw-h-6 tw-mt-5"/>  
          </div>
      <FlightsInfo 
      :flight="item"
      :departure_at="item.departure_at"
      :arrival_at="item.arrival_at"
      :city_from="item.city_from"
      :airport_from="item.airport_from"
      :airport_from_code="item.airport_from_code"
      :city_to="item.city_to"
      :city_code_to="item.city_code_to"
      :city_code_from="item.city_code_from"
      :airport_to="item.airport_to"
      :airport_to_code="item.airport_to_code"
      :itinerary="item.itinerary"
      :duration="item.duration"
      :price="item.price[0].price"
      />
      </div>
    </div>
    </template>
    <div class="loading tw-items-center tw-content-center tw-justify-center tw-flex" v-else><div>Loading...</div></div>

  </div>
</template>

<script>
import PlaneIcon from '@/assets/mobile/packageview/flights/plane.svg';
import FlightsInfo from './FlightsInfo.vue';
import { mapGetters } from 'vuex';
import { searchFlightsApi } from '@/api/flights';
import { dateForCalendar } from '@/utils/appDate.js';
export default {
  name: 'DepartingFlightsView',
  components: {
    PlaneIcon,
    FlightsInfo,
  },
  data() {
    return {
      flights: [],
      loading: true,
    };
  },
  computed: {
    ...mapGetters('search', [
      'city'
    ]),
    ...mapGetters([
      'experience',
    ]),
    sortCheapestFlight() {
      return [ ...this.flights ].sort((flightA, flightB) => {
        if (flightA.price[0].price < flightB.price[0].price) {
          return -1;
        }
        if (flightA.price[0].price > flightB.price[0].price) {
          return 1;
        }
        return 0;
      });
    },
    
  },
  methods: {
    async searchFlights() {
      const payload = {
        fly_from: this.city, 
        fly_to: this.experience.flight_destination,
        departure_date: this.checkInDate,
        return_date: this.checkOutDate,
        max_stopovers: 10,
        max_fly_duration: 50,
      };
      try {
        this.flights = await searchFlightsApi(payload);
      } catch (e) {
        console.log(e.message);
      } finally {
        this.loading = false;
      }
      this.loading = false;
    },
    HandleToReturnFlights() {
      this.$router.push({ name: 'MobileReturnFlights', query: this.$route.query });
    },
  },
  async mounted() {
    const day = 60 * 60 * 24 * 1000 * 30;
    var checkOutDay = 60 * 60 * 24 * 1000 * 3;
    this.checkInDate = dateForCalendar(new Date(new Date().getTime() + day));
    this.checkOutDate = dateForCalendar(
      new Date(new Date(this.checkInDate).getTime() + checkOutDay)
    );
    this.searchFlights();
    await this.loadExperience(this.$route.params.experienceId);
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/variables.scss";
.flight-container {
  background-color: white;
  border-radius: 0.75rem;
  width: 100%;
  margin-bottom: 1rem;
  box-shadow:  0px 4px 12px rgba(203, 203, 203, 0.12),  0px 4px 12px rgba(203, 203, 203, 0.12),  0px 4px 12px rgba(203, 203, 203, 0.12);
  border-radius: 12px;
  font-family: "SFProTextMedium";
  font-size: 0.75rem;
  line-height: 0.75rem;
  letter-spacing: -0.03rem;
  color: $paragraph;

  .flight-name {
    font-family: "SFProTextSemibold";
    font-size: 4vw;
    line-height: 1.5rem;
    letter-spacing: -0.03rem;
    color: $title;
    width: 53%
  }
  .flight-price {
    font-family: "SFProTextSemibold";
    font-size: 4.2vw;
    line-height: 1.2rem;
    letter-spacing: -0.03rem;
    margin-bottom: 0.5rem;
    margin-top: 0.75rem;
    color: $title;
  }
}

.recommend-label {
  font-family: "SFProTextSemibold";
  letter-spacing: 0.03rem;
  color: white;
  background-color: #F1BF42;
  padding: 6px 8px;
  font-size: 0.75rem;
}

.title-header {
  font-family: "SFProTextSemibold";
  font-size: 1.5rem;
  line-height: 2rem;
  color: $title;
  letter-spacing: -0.02rem;
}

.overflow-hidden {
  overflow: hidden;
}


</style>
