<template>
  <transition name="fade">
    <div class="modal tw-h-screen" v-if="show">
      <div class="modal__backdrop" @click="closeModal()" />

      <div class="modal__dialog">
        <div class="modal__header">
          <slot name="header" />
          <div class="chevron chevron-left tw-ml-4" @click="closeModal()">
            <v-icon class="package-chevron">mdi-chevron-left </v-icon>
          </div>
          <div class="divider"></div>
          <div class="tw-ml-8">
            <div class="calendar-title">Departing Flight</div>
            <div class="tw-flex tw-flex-row">
              <div class="time tw-mt-4">
                {{ this.departureTime }} - {{ this.arrivalTime }}
              </div>
              <div class="tw-rounded-full tw-ml-1 tw-mt-4 time">
                ({{
                  this.departureAirlines.length === 0 ||
                  this.departureAirlines.length === 1
                    ? "Direct"
                    : this.departureAirlines.length === 2
                    ? "1 Stop"
                    : this.departureAirlines.length - 1 + " Stops"
                }})
              </div>
            </div>
            <div class="name tw-mt-2 tw-ml-0.5">
              {{ this.flight.city_from.toUpperCase() }} -
              {{ this.flight.city_to.toUpperCase() }}
            </div>
            <div class="duration tw-mt-5 tw-ml-1">
              <div class="tw-flex tw-flex-row">
                {{ durationFromSec(this.flight.duration.departure) }}
                <div class="tw-rounded-full tw-ml-1">
                  ({{
                    this.departureAirlines.length === 0 ||
                    this.departureAirlines.length === 1
                      ? "Direct"
                      : this.departureAirlines.length === 2
                      ? "1 stop"
                      : this.departureAirlines.length - 1 + " stops"
                  }})
                </div>
                <div
                  v-if="this.departureAirlines.length === 2"
                  class="tw-flex tw-flex-row tw-mb-4"
                >
                  <Dot class="tw-w-1 tw-h-1 tw-my-auto tw-ml-2 tw-mr-2" />
                  <div
                    v-for="(item, index) in this.itinerary.slice(0, 1)"
                    :key="index"
                  >
                    <div v-if="item.city_code_to !== arrivalCity">
                      {{ layoverTime(item) }} in {{ item.city_code_to }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="tw-flex tw-flex-row tw-mb-4">
                <template v-for="airline,index in uniqueDeparture">
                  <img
                    :key="index"
                    :src="airline"
                    class="mr-2 tw-rounded-full tw-w-5 tw-h-5"
                  />
                </template>

                <div
                  v-for="(item, index) in uniqueAirlines"
                  :key="index"
                  class="airline tw-mt-0.5 tw-mr-1"
                >
                  {{ item.airline_full_name }}
                </div>
              </div>
            </div>
          </div>

          <div class="divider"></div>

          <div v-for="(item, index) in this.itinerary" :key="index">
            <div v-if="item.direction === 'departure'">
              <div class="tw-flex tw-flex-row tw-ml-4 tw-mt-6">
                <div>
                  <PlaneModal class="tw-w-12 tw-h-48 tw-mr-4" />
                </div>
                <div>
                  <div class="time tw-mt-1">
                    {{ getTime(item.departure_at) }} - {{ item.city_from }}
                  </div>
                  <div class="cabin tw-mt-2 tw-ml-0.5">
                    {{ item.airport_from }} ({{ item.airport_from_code }})
                  </div>
                  <div class="layover tw-mt-7">
                    {{ layoverTime(item) }} Flight
                  </div>
                  <div class="tw-flex tw-flex-row tw-my-2">
                    <img
                      :src="airlineImg(item.airline)"
                      class="mr-2 tw-rounded-full tw-w-5 tw-h-5"
                    />
                    <div class="tw-mt-1">
                      {{ item.flight_no }}
                    </div>
                  </div>
                  <div class="cabin tw-mb-8">
                    <div v-if="item.fare_category === 'M'">
                      Economy, Coach {{ item.fare_category }}
                    </div>
                    <div v-else-if="item.fare_category === 'W'">
                      Economy Premium, Coach {{ item.fare_category }}
                    </div>
                    <div v-else-if="item.fare_category === 'C'">
                      Business, Coach {{ item.fare_category }}
                    </div>
                    <div v-else-if="item.fare_category === 'F'">
                      First Class, Coach {{ item.fare_category }}
                    </div>
                  </div>
                  <div class="time tw-mt-1">
                    {{ getTime(item.arrival_at) }} - {{ item.city_to }}
                  </div>
                  <div class="cabin tw-mt-2 tw-ml-0.5 tw-mb-4">
                    {{ item.airport_to }} ({{ item.airport_to_code }})
                  </div>
                </div>
              </div>
              <div
                class="tw-mb-4 tw-mx-8"
                v-if="item.city_code_to !== arrivalCity"
              >
                <div class="layoverdivider"></div>
                <div class="tw-mt-4 tw-flex">
                  <div class="name tw-mr-1 tw-mt-0.5">LAYOVER</div>
                  <div class="layover">in {{ item.city_to }}</div>
                </div>
                <div class="layoverdivider tw-mt-4"></div>
              </div>
            </div>
          </div>
          <div class="divider"></div>
          <div class="tw-ml-8">
            <div class="calendar-title">Return Flight</div>
            <div class="tw-flex tw-flex-row">
              <div class="time tw-mt-4">
                {{ this.returnDepartureTime }} - {{ this.returnArrivalTime }}
              </div>
              <div class="tw-rounded-full tw-ml-1 tw-mt-4 time">
                ({{
                  this.returnAirlines.length === 0 ||
                  this.returnAirlines.length === 1
                    ? "Direct"
                    : this.returnAirlines.length === 2
                    ? "1 Stop"
                    : this.returnAirlines.length - 1 + " Stops"
                }})
              </div>
            </div>
            <div class="name tw-mt-2 tw-ml-0.5">
              {{ this.flight.city_to.toUpperCase() }} -
              {{ this.flight.city_from.toUpperCase() }}
            </div>
            <div class="duration tw-mt-5 tw-ml-1">
              <div class="tw-flex tw-flex-row">
                {{ durationFromSec(this.flight.duration.return) }}
                <div class="tw-rounded-full tw-ml-1">
                  ({{
                    this.returnAirlines.length === 0 ||
                    this.returnAirlines.length === 1
                      ? "Direct"
                      : this.returnAirlines.length === 2
                      ? "1 stop"
                      : this.returnAirlines.length - 1 + " stops"
                  }})
                </div>
                <div
                  v-if="this.returnAirlines.length > 1"
                  class="tw-flex tw-flex-row tw-mb-4"
                >
                  <div
                    v-for="(item, index) in this.itinerary.slice(
                      this.returnRouteIndex,
                      this.itinerary.length - 1
                    )"
                    :key="index"
                  >
                    <div
                      v-if="item.city_code_to !== departureCity"
                      class="tw-flex tw-flex-row"
                    >
                      <div><Dot class="tw-w-1 tw-h-1 tw-mx-2 tw-mt-1" /></div>
                      <div>
                        {{ layoverTime(item) }} in {{ item.city_code_to }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tw-flex tw-flex-row tw-mb-4">
                <template v-for="airline,index in uniqueDeparture">
                  <img
                    :key="index"
                    :src="airline"
                    class="mr-2 tw-rounded-full tw-w-5 tw-h-5"
                  />
                </template>

                <div
                  v-for="(item, index) in uniqueAirlines"
                  :key="index"
                  class="airline tw-mt-1 tw-mr-1"
                >
                  {{ item.airline_full_name }}
                </div>
              </div>
            </div>
            <div class="divider"></div>
          </div>
          <div class="divider"></div>

          <div v-for="(item, index) in this.itinerary" :key="index">
            <div v-if="item.direction === 'return'">
              <div class="tw-flex tw-flex-row tw-ml-4 tw-mt-6">
                <div>
                  <PlaneModal class="tw-w-12 tw-h-48 tw-mr-4" />
                </div>
                <div>
                  <div class="time tw-mt-1">
                    {{ getTime(item.departure_at) }} -
                    {{ item.city_from }}
                  </div>
                  <div class="cabin tw-mt-2 tw-ml-0.5">
                    {{ item.airport_from }} ({{ item.airport_from_code }})
                  </div>
                  <div class="layover tw-mt-7">
                    {{ layoverTime(item) }} Flight
                  </div>

                  <div class="tw-flex tw-flex-row tw-my-2">
                    <img
                      :src="airlineImg(item.airline)"
                      class="mr-2 tw-rounded-full tw-w-5 tw-h-5"
                    />
                    <div class="tw-mt-1">
                      {{ item.flight_no }}
                    </div>
                  </div>

                  <div class="cabin tw-mb-8">
                    <div v-if="item.fare_category === 'M'">
                      Economy, Coach {{ item.fare_category }}
                    </div>
                    <div v-else-if="item.fare_category === 'W'">
                      Economy Premium, Coach {{ item.fare_category }}
                    </div>
                    <div v-else-if="item.fare_category === 'C'">
                      Business, Coach {{ item.fare_category }}
                    </div>
                    <div v-else-if="item.fare_category === 'F'">
                      First Class, Coach {{ item.fare_category }}
                    </div>
                  </div>
                  <div class="time tw-mt-1">
                    {{ getTime(item.arrival_at) }} - {{ item.city_to }}
                  </div>
                  <div class="cabin tw-mt-2 tw-ml-0.5 tw-mb-4">
                    {{ item.airport_to }} ({{ item.airport_to_code }})
                  </div>
                </div>
              </div>
              <div
                class="tw-mb-4 tw-mx-8"
                v-if="item.city_code_to !== departureCity"
              >
                <div class="layoverdivider"></div>
                <div class="tw-mt-4 tw-flex">
                  <div class="name tw-mr-1 tw-mt-0.5">LAYOVER</div>
                  <div class="layover">in {{ item.city_to }}</div>
                </div>
                <div class="layoverdivider tw-mt-4"></div>
              </div>
            </div>
          </div>
          <div class="divider"></div>

          <div class="action-bar tw-flex tw-flex-row tw-mt-4 tw-mb-4 tw-ml-6">
            <div class="time tw-mr-2"> €{{this.price}}</div>
            <div class="layover tw-mr-16 tw-mt-0.5">/ Per traveler </div>
            <v-btn
              color="primary"
              class="reservebutton"
              @click="HandleActivities"
            >
              Continue
            </v-btn>
          </div>
        </div>

        <!-- 
        <div class="modal__footer">
          <slot name="footer" />
        </div> -->
      </div>
    </div>
  </transition>
</template>

<script>
import moment from 'moment';
import { airlineSrc } from '@/store/flights/flightsUtils';
import Dot from '@/assets/mobile/dot.svg';
import PlaneModal from '@/assets/mobile/packageview/flights/planemodal.svg';
export default {
  name: 'Modal',
  data() {
    return {
      show: false,
    };
  },
  props: [ 'flight', 'itinerary', 'city_code_to', 'city_code_from', 'price' ],
  components: {
    Dot,
    PlaneModal,
  },
  computed: {
    departureTime() {
      return this.getTime(this.flight.departure_at);
    },
    arrivalTime() {
      return this.getTime(this.flight.arrival_at);
    },
    returnRouteIndex() {
      return this.itinerary.findIndex(
        route => route.city_code_from === this.city_code_to
      );
    },
    returnRoute() {
      return (
        (this.returnRouteIndex >= 0 && this.itinerary[this.returnRouteIndex]) ||
        null
      );
    },
    departureAirlines() {
      return this.itinerary
        .slice(0, this.returnRouteIndex)
        .map(route => airlineSrc(route.airline));
    },

    uniqueDeparture() {
      return this.departureAirlines.reduce((key, value) => {
        return Object.assign(key, {
          [value.airline]: value,
        });
      }, {});
    },
    uniqueAirlines() {
      return this.itinerary.reduce((key, value) => {
        return Object.assign(key, {
          [value.direction === 'departure']: value,
        });
      }, {});
    },
    arrivalCity() {
      return this.city_code_to;
    },
    departureCity() {
      return this.city_code_from;
    },
    returnRouteArrival() {
      return this.itinerary.length
        ? this.itinerary[this.itinerary.length - 1]
        : null;
    },
    returnDepartureTime() {
      return this.getTime(this.returnRoute.departure_at);
    },
    returnArrivalTime() {
      return this.getTime(this.returnRouteArrival?.arrival_at);
    },
    returnCityFrom() {
      return this.returnRoute?.city_from;
    },
    returnCityTo() {
      return this.returnRouteArrival?.city_to;
    },
    returnAirlines() {
      return this.itinerary
        .slice(this.returnRouteIndex, this.itinerary.length)
        .map(route => airlineSrc(route.airline));
    },
    uniqueReturns() {
      return this.returnAirlines.reduce((key, value) => {
        return Object.assign(key, {
          [value.airline]: value,
        });
      }, {});
    },
  },
  methods: {
    closeModal() {
      this.show = false;
      document.querySelector('body').classList.remove('overflow-hidden');
    },
    openModal() {
      this.show = true;
      document.querySelector('body').classList.add('overflow-hidden');
    },
    getTime(strDate) {
      return moment(new Date(strDate)).format('HH:mm');
    },
    durationFromSec(seconds) {
      seconds = Number(seconds);
      var d = Math.floor(seconds / (3600 * 24));
      var h = Math.floor((seconds % (3600 * 24)) / 3600);
      var m = Math.floor((seconds % 3600) / 60);
      var dDisplay = d > 0 ? h + 24 : '';
      var mDisplay = m > 0 ? m + 'm' : '';
      if (d > 0) {
        var hDisplaywD = h > 0 ? dDisplay + 'h' : '';
        return hDisplaywD + mDisplay;
      } else {
        var hDisplay = h > 0 ? h + 'h' : '';
        return hDisplay + mDisplay;
      }
    },
    airlineImg(code) {
      return airlineSrc(code);
    },
    layoverTime(index) {
      var arrivalHour = moment(new Date(index.arrival_at)).format('HH');
      var arrivalMin = moment(new Date(index.arrival_at)).format('mm');
      var arrivalDay = moment(new Date(index.arrival_at)).format('DD MMM YYYY');
      var departureHour = moment(new Date(index.departure_at)).format('HH');
      var departureMin = moment(new Date(index.departure_at)).format('mm');
      var departureDay = moment(new Date(index.departure_at)).format(
        'DD MMM YYYY'
      );
      var sub = Math.abs(
        moment(arrivalDay, 'DD MMM YYYY')
          .startOf('day')
          .diff(moment(departureDay, 'DD MMM YYYY').startOf('day'), 'days')
      );
      var sub1 = Math.abs(arrivalHour - departureHour);
      var sub2 = Math.abs(arrivalMin - departureMin);
      if (sub > 0) {
        return sub + 'd' + sub1 + 'h' + sub2 + 'm';
      } else {
        return sub1 + 'h' + sub2 + 'm';
      }
    },
    HandleActivities() {
      this.$router.push({ name: 'MobileActivities', query: { ...this.$route.query } });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/variables.scss";
.modal {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  //top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  &__backdrop {
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
  &__dialog {
    background-color: #ffffff;
    position: relative;
    width: 100%;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    z-index: 2;
  }
  &__body {
    padding: 10px 20px 10px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  &__footer {
    padding: 10px 20px 20px;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.divider {
  border-top: 1px solid rgba(229, 231, 235, 1);
  width: 100vw;
}
.layoverdivider {
  border-top: 1px solid rgba(229, 231, 235, 1);
  width: 75vw;
}
.chevron > i {
  width: 2.75rem;
  height: 2.75rem;
  cursor: pointer;
  border-radius: 1.5rem;
}

.v-icon.package-chevron {
  color: black !important;
  padding-top: 0.5rem;
  font-size: 2rem !important;
}
.calendar-title {
  font-family: "SFProDisplaySemibold";
  font-size: 1.5rem;
  line-height: 2rem;
  color: $title;
  letter-spacing: -0.02em;
  margin-top: 0.75rem;
  margin-bottom: 8px;
}

.time {
  font-family: "SFProTextSemibold";
  font-size: 1.25rem;
  line-height: 1.25rem;
  letter-spacing: 0.03rem;
  color: $title;
}

.name {
  font-family: "SFProTextMedium";
  color: #7d7d84;
  font-size: 0.75rem;
  line-height: 0.75rem;
  letter-spacing: 0.05rem;
}

.duration {
  font-family: "SFProTextRegular";
  font-size: 0.75rem;
  line-height: 0.75rem;
  letter-spacing: -0.03rem;
  color: #414145;
}
.cabin {
  font-family: "SFProTextRegular";
  font-size: 0.75rem;
  line-height: 0.75rem;
  letter-spacing: -0.03rem;
  color: #7d7d84 !important;
}

.airline {
  font-family: "SFProTextMedium";
  font-size: 0.875rem;
  line-height: 1rem;
  letter-spacing: -0.03rem;
  color: $title;
}

.layover {
  font-family: "SFProTextSemibold";
  font-size: 0.875rem;
  line-height: 0.875rem;
  letter-spacing: 0.03rem;
  color: $paragraph;
}
.reservebutton {
  width: 8rem !important;
  min-width: 8rem !important;
  font-family: 'SFProTextSemibold';
  font-size: 0.875rem;
  line-height: 0.875rem;
  letter-spacing: 0.03rem;
  border-radius: 12px !important;
  height: 3.5rem !important;
  padding: 1rem 2rem !important;
}
.v-btn--is-elevated {
  box-shadow: unset !important;
}
</style>
