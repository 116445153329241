<template>
  <div>
    <div class="tw-mt-4 tw-ml-4" @click="$refs.modalName.openModal()">
      <div class="tw-flex tw-flex-row">
        <div class="time tw-mt-4">
          {{ this.departureTime }} - {{ this.arrivalTime }}
        </div>
        <div class="tw-mt-4 tw-ml-4">
          <template v-for="(airline, index) in uniqueDeparture">
            <img
              :key="index"
              :src="airline"
              class="mr-2 tw-rounded-full tw-w-5 tw-h-5"
            />
          </template>
        </div>
        <div class="duration tw-mt-5 tw-ml-1">
          <div class="tw-flex tw-flex-row">
            {{ durationFromSec(this.duration.departure) }}
            <div class="tw-rounded-full tw-ml-1">
              ({{
                this.departureAirlines.length === 0 ||
                this.departureAirlines.length === 1
                  ? "Direct"
                  : this.departureAirlines.length === 2
                  ? "1 stop"
                  : this.departureAirlines.length - 1 + " stops"
              }})
            </div>
          </div>
        </div>
      </div>
      <div class="name tw-mt-2 tw-ml-0.5">
        {{ this.city_from.toUpperCase() }} - {{ this.city_to.toUpperCase() }}
      </div>
      <div class="tw-flex tw-flex-row">
        <div class="time tw-mt-4">
          {{ this.returnDepartureTime }} - {{ this.returnArrivalTime }}
        </div>
        <div class="tw-mt-4 tw-ml-4">
          <template v-for="airline in uniqueReturns">
            <img
              :key="`airline_${airline}`"
              :src="airline"
              class="mr-2 tw-rounded-full tw-w-5 tw-h-5"
            />
          </template>
        </div>
        <div class="duration tw-mt-5 tw-ml-1">
          <div class="tw-flex tw-flex-row">
            {{ durationFromSec(this.duration.return) }}
            <div class="tw-rounded-full tw-ml-1">
              ({{
                this.returnAirlines.length === 0 ||
                this.returnAirlines.length === 1
                  ? "Direct"
                  : this.returnAirlines.length === 2
                  ? "1 stop"
                  : this.returnAirlines.length - 1 + " stops"
              }})
            </div>
          </div>
        </div>
      </div>
      <div class="name tw-mt-2 tw-ml-0.5 tw-mb-8">
        {{ this.returnCityFrom.toUpperCase() }} -
        {{ this.returnCityTo.toUpperCase() }}
      </div>
      <div class="flight-price tw-absolute tw-right-0 tw-mr-4 tw-bottom-2">
        € {{ this.price }}
      </div>
    </div>
    <ItineraryModal
      ref="modalName"
      :flight="this.flight"
      :itinerary="this.itinerary"
      :city_code_to="this.city_code_to"
      :city_code_from="this.city_code_from"
      :price="this.price"
    >
      <template v-slot:header> </template>
    </ItineraryModal>
  </div>
</template>

<script>
import moment from 'moment';
import { airlineSrc } from '@/store/flights/flightsUtils';
import ItineraryModal from './ItineraryModal.vue';
export default {
  name: 'FlightsDepartureInfo',
  data() {
    return {};
  },
  components: {
    ItineraryModal,
  },
  props: {
    flight: Object,
    departure_at: String,
    arrival_at: String,
    city_from: String,
    city_code_from: String,
    airport_from: String,
    airport_from_code: String,
    city_to: String,
    city_code_to: String,
    airport_to: String,
    airport_to_code: String,
    itinerary: Array,
    duration: Object,
    return_duration: String,
    price: Number,
  },
  computed: {
    departureTime() {
      return this.getTime(this.departure_at);
    },
    arrivalTime() {
      return this.getTime(this.arrival_at);
    },
    returnRouteIndex() {
      return this.itinerary.findIndex(
        route => route.city_code_from === this.city_code_to
      );
    },
    returnRoute() {
      return (
        (this.returnRouteIndex >= 0 && this.itinerary[this.returnRouteIndex]) ||
        null
      );
    },
    departureAirlines() {
      return this.itinerary
        .slice(0, this.returnRouteIndex)
        .map(route => airlineSrc(route.airline));
    },

    uniqueDeparture() {
      return this.departureAirlines.reduce((key, value) => {
        return Object.assign(key, {
          [value.airline]: value,
        });
      }, {});
    },
    arrivalCity() {
      return this.city_code_to;
    },
    returnRouteArrival() {
      return this.itinerary.length
        ? this.itinerary[this.itinerary.length - 1]
        : null;
    },
    returnDepartureTime() {
      return this.getTime(this.returnRoute.departure_at);
    },
    returnArrivalTime() {
      return this.getTime(this.returnRouteArrival?.arrival_at);
    },
    returnCityFrom() {
      return this.returnRoute?.city_from;
    },
    returnCityTo() {
      return this.returnRouteArrival?.city_to;
    },
    returnAirlines() {
      return this.itinerary
        .slice(this.returnRouteIndex, this.itinerary.length)
        .map(route => airlineSrc(route.airline));
    },
    uniqueReturns() {
      return this.returnAirlines.reduce((key, value) => {
        return Object.assign(key, {
          [value.airline]: value,
        });
      }, {});
    },
  },
  methods: {
    getTime(strDate) {
      return moment(new Date(strDate)).format('HH:mm');
    },
    durationFromSec(seconds) {
      seconds = Number(seconds);
      var d = Math.floor(seconds / (3600 * 24));
      var h = Math.floor((seconds % (3600 * 24)) / 3600);
      var m = Math.floor((seconds % 3600) / 60);
      var dDisplay = d > 0 ? h + 24 : '';
      var mDisplay = m > 0 ? m + 'm' : '';
      if (d > 0) {
        var hDisplaywD = h > 0 ? dDisplay + 'h' : '';
        return hDisplaywD + mDisplay;
      } else {
        var hDisplay = h > 0 ? h + 'h' : '';
        return hDisplay + mDisplay;
      }
    },
    airlineImg(code) {
      return airlineSrc(code);
    },
    layoverTime(index) {
      var arrivalHour = moment(new Date(index.arrival_at)).format('HH');
      var arrivalMin = moment(new Date(index.arrival_at)).format('mm');
      var arrivalDay = moment(new Date(index.arrival_at)).format('DD MMM YYYY');
      var departureHour = moment(new Date(index.departure_at)).format('HH');
      var departureMin = moment(new Date(index.departure_at)).format('mm');
      var departureDay = moment(new Date(index.departure_at)).format(
        'DD MMM YYYY'
      );
      var sub = Math.abs(
        moment(arrivalDay, 'DD MMM YYYY')
          .startOf('day')
          .diff(moment(departureDay, 'DD MMM YYYY').startOf('day'), 'days')
      );
      var sub1 = Math.abs(arrivalHour - departureHour);
      var sub2 = Math.abs(arrivalMin - departureMin);
      if (sub > 0) {
        return sub + 'd' + sub1 + 'h' + sub2 + 'm';
      } else {
        return sub1 + 'h' + sub2 + 'm';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/variables.scss";
.flight-price {
  font-family: "SFProTextSemibold";
  font-size: 1.25rem;
  line-height: 1.2rem;
  letter-spacing: -0.03rem;
  margin-bottom: 0.5rem;
  margin-top: 0.75rem;
  color: $title;
}

.name {
  font-family: "SFProTextMedium";
  color: #7d7d84;
  font-size: 0.75rem;
  line-height: 0.75rem;
  letter-spacing: 0.05rem;
}

.time {
  font-family: "SFProTextSemibold";
  font-size: 1.25rem;
  line-height: 1.25rem;
  letter-spacing: 0.03rem;
  color: $title;
}

.duration {
  font-family: "SFProTextRegular";
  font-size: 0.75rem;
  line-height: 0.75rem;
  letter-spacing: -0.03rem;
  color: #414145;
}

.airline {
  font-family: "SFProTextMedium";
  font-size: 0.875rem;
  line-height: 1rem;
  letter-spacing: -0.03rem;
  color: $title;
}
</style>
